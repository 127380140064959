<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" class="mt-5">
          <v-row>
            <v-col cols="12" sm="12" md="12" style="text-align: center">
              <div v-if="errMessage">
                <p class="red--text">{{ errMessage }}</p>
              </div>
            </v-col>

            <!-- Title -->
            <v-col cols="12" sm="6" md="6">
              <label>{{ $t("Title") }} </label>
              <v-text-field
                v-model="editedItem.title"
                :rules="[validationRules.required, validationRules.maxLength50]"
                solo
                dense
                @focus="onFocus()"
              ></v-text-field>
              <div v-if="validation_errors.name">
                <p
                  class="red--text"
                  v-for="(error, index) in validation_errors.name"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </v-col>
            <!-- Mark -->
            <v-col cols="12" sm="6" md="6" style="padding-right: 0 !important">
              <label>{{ $t("Mark") }}</label>
              <v-text-field
                v-model="editedItem.mark"
                :rules="[validationRules.required, validationRules.numeric]"
                solo
                dense
                @focus="onFocus()"
              ></v-text-field>
              <div v-if="validation_errors.mark">
                <p
                  class="red--text"
                  v-for="(error, index) in validation_errors.mark"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </v-col>
            <!-- grades -->
            <v-col cols="12" sm="12" md="6">
              <label>{{ $t("Grade") }}</label>
              <v-select
                :items="grades"
                item-text="name"
                item-value="id"
                v-model.trim="editedItem.academic_grade_year_id"
                :rules="[validationRules.required]"
                solo
                dense
                @focus="onFocus()"
                @change="getClasses('edit')"
              ></v-select>
              <div v-if="validation_errors.academic_grade_year_id">
                <p
                  class="red--text"
                  v-for="(error,
                  index) in validation_errors.academic_grade_year_id"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </v-col>
            <!-- Class  -->
            <v-col cols="12" sm="12" md="6">
              <label>{{ $t("Class") }}</label>

              <v-autocomplete
                :items="classes"
                item-text="name"
                item-value="id"
                v-model.trim="editedItem.class_ids"
                :rules="[validationRules.required]"
                :multiple="true"
                solo
                dense
              ></v-autocomplete>
              <div v-if="validation_errors.class_ids">
                <p
                  class="red--text"
                  v-for="(error, index) in validation_errors.class_ids"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </v-col>
            <!-- subject -->
            <v-col cols="12" sm="12" md="6">
              <label>{{ $t("Subject") }}</label>
              <v-select
                :items="subjects"
                item-text="name"
                item-value="id"
                v-model.trim="editedItem.academic_grade_subjects_id"
                :rules="[validationRules.required]"
                solo
                dense
                @focus="onFocus()"
              ></v-select>
              <div v-if="validation_errors.academic_grade_subjects_id">
                <p
                  class="red--text"
                  v-for="(error,
                  index) in validation_errors.academic_grade_subjects_id"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </v-col>
            <!-- Teacher -->
            <v-col cols="12" sm="12" md="6" v-if="!teacher">
              <label>{{ $t("Teacher") }}</label>
              <v-select
                :items="teachers"
                item-text="name"
                item-value="id"
                v-model.trim="editedItem.teacher_id"
                :rules="[validationRules.required]"
                solo
                dense
                @focus="onFocus()"
              ></v-select>
              <div v-if="validation_errors.teacher_id">
                <p
                  class="red--text"
                  v-for="(error, index) in validation_errors.teacher_id"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </v-col>
            <!-- publish_date -->
            <v-col cols="12" sm="6" md="6">
              <label>{{ $t("Publish Date") }}</label>

              <v-datetime-picker
                solo
                dense
                v-model="editedItem.publish_date"
                dateFormat="yyyy-MM-dd"
                timeFormat="HH:mm:ss"
                :rules="[
                  validationRules.required,
                  startDateTimeOrEqualRule(
                    editedItem.publish_date,
                    editedItem.due_date,
                    $t('Start date should be less than or equal to end date')
                  ),
                ]"
                outlined
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-datetime-picker>
              <p class="error--text" v-if="startDateError">
                {{ this.startDateError }}
              </p>

              <div v-if="empty_publish_date">
                <p class="red--text">{{ $t("This field is required") }}</p>
              </div>
            </v-col>
            <!-- due date -->
            <v-col cols="12" sm="6" md="6">
              <label>{{ $t("Due Date") }}</label>
              <v-datetime-picker
                solo
                dense
                v-model="editedItem.due_date"
                dateFormat="yyyy-MM-dd"
                timeFormat="HH:mm:ss"
                :rules="[
                  validationRules.required,
                  startDateTimeOrEqualRule(
                    editedItem.publish_date,
                    editedItem.due_date,
                    $t('Start date should be less than or equal to end date')
                  ),
                ]"
                outlined
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-datetime-picker>
              <p class="error--text" v-if="endDateError">
                {{ this.endDateError }}
              </p>

              <div v-if="empty_due_date">
                <p class="red--text">{{ $t("This field is required") }}</p>
              </div>

              <div v-if="validation_errors.due_date">
                <p
                  class="red--text"
                  v-for="(error, index) in validation_errors.due_date"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-checkbox
                v-model="editedItem.auto_correction"
                :label="$t('Auto Correction')"
              ></v-checkbox>
              <div v-if="editedItem.auto_correction">
                <p class="red--text">
                  {{ $t("Essay questions won't be available for this option") }}
                </p>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-checkbox
                v-model="editedItem.randomiz"
                :label="$t('Randomize Questions Order')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-checkbox
                v-model="editedItem.allow_multi_submissions"
                :label="$t('Allow multi submissions')"
              ></v-checkbox>
            </v-col>
            <!-- email reminder -->
            <v-col cols="12" sm="12" md="12">
              <v-checkbox
                v-model="editedItem.email_notification_reminder"
                :label="
                  $t('Email notification (If Student didn’t submit HomeWork)')
                "
              ></v-checkbox>
            </v-col>

            <!-- email reminder -->
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="editedItem.allow_multi_submissions"
            >
              <v-checkbox
                v-model="editedItem.email_notification_comments"
                :label="$t('Email notification When New Comment Added')"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
// import moment from "moment";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
    "teacher",
    // "teachers",
  ],
  mixins: [validationMixin],
  name: "AddEditHomeWorkComponent",
  data() {
    return {
      startDateError: "",
      endDateError: "",
      menu2: false,
      strShow: false,
      empty_publish_date: false,
      empty_due_date: false,
      valid: true,
      validationMsg: "",
      durationValid: false,
      validation_errors: {
        name: "",
        subject_id: "",
        qualifier_id: "",
        mark: "",
        exam_date: "",
        academic_year_id: "",
        class_id: "",
      },

      loading: false,
      errMessage: "",
      classes: [],
      grades: [],
      teachers: [],
      subjects: [],
    };
  },
  watch: {
    // "editedItem.academic_grade_year_id": {
    //   handler() {
    //     this.editedItem.class_ids = [];
    //   },
    // },
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
        if (this.editedIndex > -1) {
          this.getClasses();
        }
      },
    },
    editedIndex: function(value) {
      if (value != -1) {
        this.getQualifiers();
      } else if (value == -1) {
        this.qualifiers = [];
      }
    },
  },
  methods: {
    onFocus() {
      this.validation_errors = {
        name: "",
        subject_id: "",
        qualifier_id: "",
        mark: "",
        // exam_date: "",
        academic_year_id: "",
      };
    },
    close() {
      this.AddEditdialog = false;
      this.editedItem = {};
      this.validation_errors = {
        name: "",
        subject_id: "",
        qualifier_id: "",
        mark: "",
        // exam_date: "",
        academic_year_id: "",
      };
      this.qualifiers = [];
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (
        !this.editedItem.publish_date ||
        !this.editedItem.due_date ||
        this.startDateError != "" ||
        this.endDateError != ""
      ) {
        if (!this.editedItem.publish_date) {
          this.empty_publish_date = true;
        }
        if (!this.editedItem.due_date) {
          this.empty_due_date = true;
        }
      } else {
        this.empty_publish_date = false;
        this.empty_due_date = false;
        if (!this.valid) {
          this.$refs.form.validate();
        } else {
          this.loading = true;
          // update
          if (this.editedIndex > -1) {
            axios
              .post(
                this.getApiUrl +
                  "/" +
                  this.endPoints.edit +
                  "/" +
                  this.editedItem.id,
                this.editedItem,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                this.resetValidation();
                this.loading = false;
                if (response.data.status.error == true) {
                  Object(
                    this.validation_errors,
                    response.data.status.validation_errors
                  );
                  if (response.data.status.validation_errors.length == 0) {
                    this.errMessage = response.data.status.message;
                  }
                } else {
                  this.AddEditdialog = false;
                  // let data = response.data.data;
                  // let classId = response.data.data.class_id;
                  // let examId = response.data.data.id;

                  // if (data.online_exam) {
                  //   this.$router.push(
                  //     {
                  //       path: "/" + "createOnlineExam/" + classId + "/" + examId
                  //     },
                  //     () => {}
                  //   );
                  // }
                  // this.$emit(
                  //   "childToParent",
                  //   false,
                  //   response.data.data,
                  //   this.editedIndex
                  // );
                  // this.qualifiers = [];
                  this.close();
                  // this.qualifiers = [];
                }
              });
          } else {
            // store
            axios
              .post(
                this.getApiUrl + "/" + this.endPoints.create,
                this.editedItem,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                this.resetValidation();
                if (response.data.status.error == true) {
                  Object.assign(
                    this.validation_errors,
                    response.data.status.validation_errors
                  );
                  if (response.data.status.validation_errors.length == 0) {
                    this.errMessage = response.data.status.message;
                  }
                } else {
                  this.disabledSubmitbtn = true;
                  let homeId = response.data.data;
                  this.editedItem = {
                    title: "",
                    mark: "",
                    academic_grade_year_id: "",
                    academic_grade_subjects_id: "",
                    class_ids: [],
                    teacher_id: "",
                    publish_date: "",
                    due_date: "",
                  };
                  this.classes = [];
                  this.grades = [];
                  this.teachers = [];
                  this.subjects = [];

                  this.$router.push(
                    {
                      path: "/" + "homework/" + homeId,
                    },
                    () => {}
                  );

                  this.close();
                  // window.location.reload();
                  // console.log(homeId);
                }

                this.loading = false;
              });
          }
        }
      }
    },
    getgrades() {
      axios
        .get(this.getApiUrl + "/homework/getGrade", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.grades = response.data.data;
        });
    },
    getClasses(edit) {
      console.log(edit);
      axios
        .get(
          this.getApiUrl +
            "/homework/getClasses/" +
            this.editedItem.academic_grade_year_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.classes = response.data.data.classes;
          this.subjects = response.data.data.subjects;
          this.teachers = response.data.data.teachers;

          if (edit) {
            this.editedItem.class_ids = [];
          }
        });
    },
  },
  mounted() {
    this.getgrades();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.space-name {
  margin-top: -15px !important;
}

.betweencolon {
  position: absolute;
  top: 3rem;
  left: 5%;
}

.dur-error {
  margin-top: 0 !important;
}
</style>
